define('ember-cp-validations/utils/utils', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.requireModule = requireModule;
  exports.unwrapString = unwrapString;

  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  function requireModule(module) {
    return self.requirejs.has(module) ? self.require(module)['default'] : undefined;
  }

  function unwrapString(input) {
    if (input && input instanceof _ember['default'].Handlebars.SafeString) {
      return input.toString();
    }

    return input;
  }
});