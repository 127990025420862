define("ember-side-menu/components/content-backdrop", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    var Component = _ember["default"].Component;
    var computed = _ember["default"].computed;
    var htmlSafe = _ember["default"].String.htmlSafe;
    var alias = _ember["default"].computed.alias;
    var service = _ember["default"].inject.service;
    var get = _ember["default"].get;

    exports["default"] = Component.extend({
        sideMenu: service(),

        progress: alias("sideMenu.progress"),

        attributeBindings: ["style"],
        classNames: ["content-backdrop"],

        style: computed("progress", function () {
            var progress = get(this, "progress");
            var opacity = progress / 100;
            var visibility = progress === 0 ? "hidden" : "visible";
            var transition = "none";

            if (progress === 100) {
                transition = "opacity 0.2s ease-out";
            } else if (progress === 0) {
                transition = "visibility 0s linear 0.2s, opacity 0.2s ease-out";
            }

            return htmlSafe("opacity: " + opacity + "; visibility: " + visibility + "; transition: " + transition);
        }),

        click: function click() {
            get(this, "sideMenu").close();
        }
    });
});