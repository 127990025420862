define('ember-css-modules/mixins/component-lookup-mixin', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    componentFor: function componentFor(name, owner, options) {
      var component = this._super(name, owner, options);

      // If we're doing a local lookup, don't interfere; wait for the global fallback if necessary
      if (options && options.source) {
        return component;
      }

      // Ensure components are always managed my the container and thus have a connection to their styles
      if (!component && hasRegistration(owner, 'template:components/' + name)) {
        findRegistry(owner).register('component:' + name, _ember['default'].Component);
        component = this._super(name, owner, options);
      }

      return component;
    }
  });

  // There are like a dozen different ways of registering something across our various supported versions of Ember,
  // all varying levels of public-ish. This threads that needle without triggering deprecation warnings.
  function findRegistry(owner) {
    return owner._registry || (owner.register ? owner : owner.registry);
  }

  function hasRegistration(owner, name) {
    var registry = findRegistry(owner);
    return registry.hasRegistration ? registry.hasRegistration(name) : registry.has(name);
  }
});