define('ember-cli-notifications/components/notification-container', ['exports', 'ember', 'ember-cli-notifications/templates/components/notification-container', 'ember-cli-notifications/styles/components/notification-container'], function (exports, _ember, _emberCliNotificationsTemplatesComponentsNotificationContainer, _emberCliNotificationsStylesComponentsNotificationContainer) {
  'use strict';

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;

  exports['default'] = Component.extend({
    layout: _emberCliNotificationsTemplatesComponentsNotificationContainer['default'],
    styles: _emberCliNotificationsStylesComponentsNotificationContainer['default'],

    classNameBindings: ['computedPosition'],

    computedPosition: computed('position', function () {
      if (this.get('position')) return this.get('styles.c-notification__container--' + this.get('position'));

      return this.get('styles.c-notification__container--top');
    })
  });
});