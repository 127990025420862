define("ember-side-menu/components/side-menu-toggle", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    var Component = _ember["default"].Component;
    var get = _ember["default"].get;
    var service = _ember["default"].inject.service;

    exports["default"] = Component.extend({
        sideMenu: service(),

        classNameBindings: ["side"],
        classNames: ["side-menu-toggle"],

        side: "left",

        click: function click() {
            get(this, "sideMenu").toggle();
        }
    });
});