define("ember-cli-notifications/styles/addon", ["exports"], function (exports) {
  "use strict";

  exports["default"] = {
    "--green": "#64ce83",
    "--blue": "#3ea2ff",
    "--orange": "#ff7f48",
    "--red": "#e74c3c",
    "--spacing-1": ".5rem",
    "--spacing-2": "1rem"
  };
});