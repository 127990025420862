define('ember-tooltips/utils', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;
  var onComponentTarget = computed(function () {
    var parentView = this.get('parentView');

    if (!parentView) {
      console.warn('No parentView found');

      return null;
    } else {
      return '#' + parentView.get('elementId');
    }
  });
  exports.onComponentTarget = onComponentTarget;
});