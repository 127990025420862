define('ember-tooltips/components/popover-on-element', ['exports', 'ember', 'ember-tooltips/components/tooltip-and-popover', 'ember-tooltips/templates/components/popover-on-element'], function (exports, _ember, _emberTooltipsComponentsTooltipAndPopover, _emberTooltipsTemplatesComponentsPopoverOnElement) {
  'use strict';

  var $ = _ember['default'].$;
  var run = _ember['default'].run;

  exports['default'] = _emberTooltipsComponentsTooltipAndPopover['default'].extend({

    /* Options */
    hideDelay: 250,

    /* Properties */
    layout: _emberTooltipsTemplatesComponentsPopoverOnElement['default'],
    classNames: ['ember-popover'],
    _isMouseInside: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var event = this.get('event');
      var target = this.get('target');
      var $target = $(target);
      var $popover = this.$();

      if (event === 'none') {

        return;
      } else if (event === 'hover') {

        var _showOn = this.get('_showOn');
        var _hideOn = this.get('_hideOn');

        // _showOn == 'mouseenter'
        $target.on(_showOn, function () {
          return _this.show();
        });

        // _hideOn == 'mouseleave'
        $target.add($popover).on(_hideOn, function () {
          run.later(function () {
            if (!_this.get('_isMouseInside')) {
              _this.hide();
            }
          }, +_this.get('hideDelay'));
        });

        // we must use mouseover/mouseout because they correctly
        // register hover interactivity when spacing='0'
        $target.add($popover).on('mouseover', function () {
          return _this.set('_isMouseInside', true);
        });
        $target.add($popover).on('mouseout', function () {
          return _this.set('_isMouseInside', false);
        });
      } else if (event === 'click') {

        $(document).on('click.' + target, function (event) {
          // this lightweight, name-spaced click handler is necessary to determine
          // if a click is NOT on $target and NOT an ancestor of $target.
          // If so then it must be a click elsewhere and should close the popover
          // see... https://css-tricks.com/dangers-stopping-event-propagation/
          var isClickedElementElsewhere = _this._isElementElsewhere(event.target);
          var isPopoverShown = _this.get('isShown');

          if (isClickedElementElsewhere && isPopoverShown) {
            _this.hide();
          }
        });

        // we use mousedown because it occurs before the focus event
        $target.on('mousedown', function (event) {
          // $target.on('mousedown') is called when the $popover is
          // clicked because the $popover is contained within the $target.
          // This will ignores those types of clicks.
          var isMouseDownElementInPopover = _this._isElementInPopover(event.target);
          if (isMouseDownElementInPopover) {
            return;
          }
          _this.toggle();
        });
      }

      $target.on('focus', function () {
        return _this.show();
      });

      $popover.on('focusout', function () {
        // use a run.later() to allow the 'focusout' event to finish handling
        run.later(function () {
          var isFocusedElementElsewhere = _this._isElementElsewhere(document.activeElement);
          if (isFocusedElementElsewhere) {
            _this.hide();
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var target = this.get('target');
      var $target = $(target);
      var $popover = this.$();
      var _showOn = this.get('_showOn');
      var _hideOn = this.get('_hideOn');

      $target.add($popover).off(_showOn + ' mouseover ' + _hideOn + ' mouseout mousedown focus focusout');

      $(document).off('click.' + target);
    },
    _isElementInPopover: function _isElementInPopover(newElement) {
      // determines if newElement is $popover or contained within $popover
      var $popover = this.$();
      return $popover.is(newElement) || $popover.find(newElement).length;
    },
    _isElementElsewhere: function _isElementElsewhere(newElement) {
      // determines if newElement is not $target, not $popover, and not contained within either
      var $target = $(this.get('target'));

      var isNewElementOutsideTarget = !$target.is(newElement) && !$target.find(newElement).length;
      var isNewElementOutsidePopover = !this._isElementInPopover(newElement);

      return isNewElementOutsideTarget && isNewElementOutsidePopover;
    },
    actions: {
      hide: function hide() {
        this.hide();
      }
    }
  });
});