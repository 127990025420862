define("ember-side-menu/services/side-menu", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    var equal = _ember["default"].computed.equal;
    var get = _ember["default"].get;
    var set = _ember["default"].set;
    var Service = _ember["default"].Service;

    exports["default"] = Service.extend({
        // progress 0-100 %
        progress: 0,
        isOpen: equal("progress", 100),
        isClosed: equal("progress", 0),
        isSlightlyOpen: false,

        close: function close() {
            set(this, "progress", 0);
            set(this, "isSlightlyOpen", false);
        },

        open: function open() {
            set(this, "progress", 100);
            set(this, "isSlightlyOpen", false);
        },

        toggle: function toggle() {
            if (get(this, "isOpen")) {
                this.close();
            } else {
                this.open();
            }
        }
    });
});