define('ember-changed-relationships/mixins/changed-relationships', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  'use strict';

  var Mixin = _ember['default'].Mixin;

  var mapById = function mapById(member) {
    return member.id;
  };

  exports['default'] = Mixin.create({
    changedRelationships: function changedRelationships() {
      var _this = this;

      //ids are always undefined until initialized
      if (this.get('_internalModel.dataHasInitialized') === false) {
        return;
      }

      var relationships = {};

      this.eachRelationship(function (name, meta) {
        var basePath = '_internalModel._relationships.initializedRelationships.' + name;

        if (meta.kind === 'belongsTo') {
          var initialId = _this.get(basePath + '.canonicalMembers.list.firstObject.id');
          var newId = _this.get(name + '.id');

          if (initialId !== newId) {
            relationships[name] = [initialId, newId];
          }
        } else if (meta.kind === 'hasMany') {
          var initialIds = _this.get(basePath + '.canonicalMembers.list').map(mapById);
          var newIds = _this.get(basePath + '.members.list').map(mapById);

          if ((0, _lodash.isEqual)(initialIds, newIds) === false) {
            relationships[name] = [initialIds, newIds];
          }
        }
      });

      return relationships;
    }
  });
});

//TODO - probably don't need lodash for a single import