define('ember-singularity/services/unified-event-handler', ['exports'], function (exports) {

  /**
   * TODO: Make this globally configurable
   * The interval at which to dispatch events (all events are throttled).
   * @type {Number}
   */
  'use strict';

  var EVENT_INTERVAL = Ember.testing ? 0 : 50;

  /**
   * An array of possible global objects to bind to.
   * @type {Array}
   */
  /**
   * The Unified Event Handler helps control DOM event listeners by taking normal
   * DOM events and binding them to Ember Events that are united into one trigger.
   * In other words, this means that instead of having multiple listeners for a
   * single DOM event, we have one listener for a single DOM event that then
   * triggers multiple callbacks via Ember events.
   *
   * Why do this? There are two primary motivations:
   * 1. Reduce number of DOM listeners
   * 2. Leverage Ember's event system
   */
  var GLOBALS = ['window', 'document'];

  /**
   * The name of the property for the handler map (since we access it a lot).
   * @type {String}
   */
  var _HANDLER_MAP = '_handlerMap';

  // Generates an incremental id number, used for labeling Ember events
  var generateId = (function () {
    var id = 0;

    return function () {
      return ++id + '';
    };
  })();

  exports['default'] = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      this._super.apply(this, arguments);

      this[_HANDLER_MAP] = Object.create(null);
      this._throttledEventTimers = [];
    },

    isFastBoot: Ember.computed(function () {
      var fastbootService = Ember.getOwner(this).lookup('service:fastboot');
      return fastbootService ? fastbootService.get('isFastBoot') : false;
    }),

    /**
     * Registers an event type for a specific target to be unified into a single
     * event listener
     * @param {String} target
     * @param {String} eventName
     * @param {Function} callback
     * @param {Number} eventInterval interval in milliseconds at which event will be dispatched (default 50ms)
     * @return {Void}
     */
    register: function register(target, eventName, callback) {
      var eventInterval = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : EVENT_INTERVAL;

      if (this.get('isFastBoot')) {
        return;
      }
      var handlerInfo = this._registerDOMHandler(target, eventName, eventInterval);
      this._registerEmberHandler(handlerInfo, callback);
    },

    /**
     * Given a selector return a DOM element
     * @param  {String} target The selector string
     * @return {Object}        The DOM representation of the string
     */
    _lookupElement: function _lookupElement(target) {
      var isGlobal = GLOBALS.indexOf(target) > -1;
      var targetElement = void 0;

      if (typeof target === 'string' && !isGlobal) {
        targetElement = document.querySelector(target);
      } else if (isGlobal) {
        switch (target) {
          case 'window':
            targetElement = window;
            break;
          case 'document':
            targetElement = document;
            break;
        }
      } else {
        throw new Error('UnifiedEventHandler inverts control and looks up elements on your behalf. Please call register with a selector string.');
      }

      if (!targetElement) {
        throw new Error('The target selector ' + target + ' was passed, but could not be retrieved from the DOM.');
      }

      return targetElement;
    },

    /**
     * Registers a DOM event handler for a specific target and event type; returns
     * the info of the DOM handler on completion
     * @private
     * @param {EventTarget} target
     * @param {String} eventName
     * @param {Number} eventInterval interval in milliseconds at which event will be dispatched
     * @return {Object} handlerInfo
     */
    _registerDOMHandler: function _registerDOMHandler(target, eventName, eventInterval) {
      var _this = this;

      // Check if the target already has an event listener for this type of event
      var handlerInfo = this._getTargetEventHandler(target, eventName);

      if (!handlerInfo) {
        // Add new DOM event listener since there is none
        var emberEventName = eventName + '.' + generateId();
        var throttledEventCallback = function throttledEventCallback(originalEvent) {
          return _this.trigger(emberEventName, originalEvent);
        };
        var trigger = this._runThrottle.bind(this, throttledEventCallback, eventInterval);
        var targetElement = this._lookupElement(target);

        targetElement.addEventListener(eventName, trigger);

        // Register the handler info into the map
        var handlerMap = this[_HANDLER_MAP];
        var targetHandlers = handlerMap[target];

        handlerInfo = {
          trigger: trigger,
          emberEventName: emberEventName,
          targetElement: targetElement,
          throttledEventCallback: throttledEventCallback,
          emberHandlers: []
        };

        if (!targetHandlers) {
          handlerMap[target] = targetHandlers = Object.create(null);
        }

        targetHandlers[eventName] = handlerInfo;
      }

      return handlerInfo;
    },

    /**
     * Registers the Ember event handler associated with a DOM handler
     * @private
     * @param {Object} domHandlerInfo
     * @param {Function} callback
     * @return {Void}
     */
    _registerEmberHandler: function _registerEmberHandler(domHandlerInfo, callback) {
      // Register the callback as a new ember handler
      domHandlerInfo.emberHandlers.push(callback);

      // Add the ember event listener
      this.on(domHandlerInfo.emberEventName, callback);
    },
    willDestroy: function willDestroy() {
      this._throttledEventTimers.forEach(function (throttledEvent) {
        return Ember.run.cancel(throttledEvent);
      });

      var handlerMap = this[_HANDLER_MAP];

      for (var target in handlerMap) {
        var handlerTarget = handlerMap[target];
        for (var eventName in handlerTarget) {
          var handlerInfo = handlerTarget[eventName];
          var emberHandlers = handlerInfo.emberHandlers.slice(0);
          for (var i = 0; i < emberHandlers.length; i++) {
            this.unregister(target, eventName, emberHandlers[i]);
          }
        }
      }

      this._super();
    },

    /**
     * Unregisters a previously bound event
     * @param {String} target
     * @param {String} eventName
     * @param {Function} callback
     * @return {Void}
     */
    unregister: function unregister(target, eventName, callback) {
      if (!this.get('isFastBoot')) {
        // Get the handler for the passed in id
        var handlerMap = this[_HANDLER_MAP];
        var handlerTarget = handlerMap[target];

        // exit early as this targets events were previously unregistered
        if (!handlerTarget) {
          return;
        }

        var handlerInfo = handlerTarget[eventName];

        // exit early as this targets event has been unregistered
        if (!handlerInfo) {
          return;
        }

        var targetElement = handlerInfo.targetElement;

        // Remove the associated Ember event listener
        this.off(handlerInfo.emberEventName, callback);

        for (var i = 0, cb; cb = handlerInfo.emberHandlers && handlerInfo.emberHandlers[i]; ++i) {
          if (cb === callback) {
            handlerInfo.emberHandlers.splice(i, 1);
          }
        }

        // Check if all the ember event listeners for the DOM event listener have been destroyed
        if (!handlerInfo.emberHandlers.length) {
          // If so, unbind the DOM event listener as well
          targetElement.removeEventListener(eventName, handlerInfo.trigger);
          delete handlerTarget[eventName];

          // If the target has no more event listeners
          if (!Object.keys(handlerTarget).length) {
            // Delete the key
            delete this[_HANDLER_MAP][target];
          }
        }
      }
    },

    /**
     * Gets the event handler info (if any) for a specific type of event on a
     * specified target
     * @private
     * @param {EventTarget} target
     * @param {String} eventName
     * @return {Object}
     */
    _getTargetEventHandler: function _getTargetEventHandler(target, eventName) {
      var handlerMap = this[_HANDLER_MAP];
      var targetHandlers = handlerMap && handlerMap[target];
      return targetHandlers && targetHandlers[eventName] || undefined;
    },

    /**
     * Triggers a given Ember event at a throttled rate
     * @private
     * @param {Function} throttledEventCallback - A method that will be called at a throttled rate
     * @param {Number} eventInterval - Throttle rate time in milliseconds
     * @return {Void}
     */
    _runThrottle: function _runThrottle(throttledEventCallback, eventInterval, originalEvent) {
      var throttleId = Ember.run.throttle(this, throttledEventCallback, originalEvent, eventInterval);
      this._throttledEventTimers.push(throttleId);
    }
  });
});