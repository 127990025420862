define('ember-scroll-to/components/scroll-to', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({

    // ----- Arguments -----
    href: null, // Required
    label: undefined,
    duration: undefined,
    easing: undefined,
    offset: undefined,

    // ----- Overridden properties -----
    tagName: 'a',
    attributeBindings: ['href'],

    // ----- Services -----
    scroller: _ember['default'].inject.service(),

    // ----- Computed properties -----
    jQueryElement: _ember['default'].computed('href', function () {
      var href = this.get('href');

      return this.get('scroller').getJQueryElement(href);
    }),

    // ----- Events -----
    scroll: _ember['default'].on('click', function (evt) {
      var _this = this;

      evt.stopPropagation();
      evt.preventDefault();

      this.get('scroller').scrollVertical(this.get('jQueryElement'), {
        duration: this.get('duration'),
        offset: this.get('offset'),
        easing: this.get('easing'),
        complete: function complete() {
          return _ember['default'].run(_this, _this.sendAction, 'afterScroll');
        }
      });
    })
  });
});