define("ember-cli-notifications/styles/components/notification-message", ["exports"], function (exports) {
  "use strict";

  exports["default"] = {
    "--icon-width": "30px",
    "--icon-position": "10px",
    "--icon-color": "color(#fff a(.74))",
    "--icon-lighten-background": "color(#fff a(.2))",
    "--countdown-lighten-background": "color(#fff a(.4))",
    "--notification-max-height": "800px",
    "--notification-border-radius": "3px",
    "--red": "#e74c3c",
    "--blue": "#3ea2ff",
    "--green": "#64ce83",
    "--orange": "#ff7f48",
    "--spacing-1": ".5rem",
    "--spacing-2": "1rem",
    "c-notification": "_c-notification_1k7w9i",
    "notification-hide": "_notification-hide_1k7w9i",
    "notification-shrink": "_notification-shrink_1k7w9i",
    "c-notification--clickable": "_c-notification--clickable_1k7w9i",
    "c-notification--in": "_c-notification--in_1k7w9i",
    "notification-show": "_notification-show_1k7w9i",
    "c-notification__content": "_c-notification__content_1k7w9i",
    "c-notification__icon": "_c-notification__icon_1k7w9i",
    "c-notification__close": "_c-notification__close_1k7w9i",
    "c-notification__countdown": "_c-notification__countdown_1k7w9i",
    "notification-countdown": "_notification-countdown_1k7w9i",
    "c-notification--info": "_c-notification--info_1k7w9i _c-notification_1k7w9i",
    "c-notification--success": "_c-notification--success_1k7w9i _c-notification_1k7w9i",
    "c-notification--warning": "_c-notification--warning_1k7w9i _c-notification_1k7w9i",
    "c-notification--error": "_c-notification--error_1k7w9i _c-notification_1k7w9i"
  };
});