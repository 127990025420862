define('ember-promise-helpers/promise-state', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var RSVP = _ember['default'].RSVP;
  var Promise = RSVP.Promise;

  var DEFAULT_STATE = {
    isPending: true,
    isFulfilled: false,
    isRejected: false,
    error: null,
    value: null
  };

  var PromiseState = (function () {
    function PromiseState(watchedPromise) {
      _classCallCheck(this, PromiseState);

      this._watchedPromise = Promise.resolve(watchedPromise);
      this._deferred = RSVP.defer();
      this._promise = this.deferred.promise;
      this._setWatchers();
      this.setState(DEFAULT_STATE);
    }

    _createClass(PromiseState, [{
      key: '_setWatchers',
      value: function _setWatchers() {
        var _this = this;

        this._watchedPromise.then(function (value) {
          _this._resolve(value);
        })['catch'](function (error) {
          _this._reject(error);
          return Promise.reject(error);
        })['finally'](function () {
          _this._settle();
        });
      }
    }, {
      key: '_resolve',
      value: function _resolve(value) {
        var newState = {
          isFulfilled: true,
          isRejected: false,
          value: value
        };
        return this._deferred.resolve(value);
      }
    }, {
      key: '_reject',
      value: function _reject(value) {
        return this._deferred.reject(value);
      }
    }, {
      key: '_settle',
      value: function _settle() {
        this.setState({
          isPending: false
        });

        this._resolve;
      }
    }]);

    return PromiseState;
  })();

  function merge() {
    var mergedHash = {};

    for (var _len = arguments.length, objects = Array(_len), _key = 0; _key < _len; _key++) {
      objects[_key] = arguments[_key];
    }

    for (var i = 0; i < objects.length; i++) {
      var object = objects[i];
      var keys = Object.keys(object);

      for (var _i = 0; _i < keys.length; _i++) {
        var key = keys[_i];
        mergedHash[key] = object[key];
      }
    }

    return mergedHash;
  }
});