define("ember-side-menu/initializers/side-menu", ["exports"], function (exports) {
    "use strict";

    exports.initialize = initialize;

    function initialize() /* application */{
        // application.inject("route", "foo", "service:foo");
    }

    exports["default"] = {
        name: "side-menu",
        initialize: initialize
    };
});