define("ember-cli-notifications/styles/components/notification-container", ["exports"], function (exports) {
  "use strict";

  exports["default"] = {
    "--container-position": "10px",
    "--container-zindex": "1060",
    "--container-width": "80%",
    "--container-max-with": "400px",
    "c-notification__container": "_c-notification__container_qmx12s",
    "c-notification__container--top": "_c-notification__container--top_qmx12s _c-notification__container_qmx12s",
    "c-notification__container--top-left": "_c-notification__container--top-left_qmx12s _c-notification__container_qmx12s",
    "c-notification__container--top-right": "_c-notification__container--top-right_qmx12s _c-notification__container_qmx12s",
    "c-notification__container--bottom": "_c-notification__container--bottom_qmx12s _c-notification__container_qmx12s",
    "c-notification__container--bottom-left": "_c-notification__container--bottom-left_qmx12s _c-notification__container_qmx12s",
    "c-notification__container--bottom-right": "_c-notification__container--bottom-right_qmx12s _c-notification__container_qmx12s"
  };
});