define('ember-css-modules/initializers/ember-css-modules', ['exports', 'ember-css-modules/extensions'], function (exports, _emberCssModulesExtensions) {
  'use strict';

  exports.initialize = initialize;

  // Ensure our core extensions are loaded before the app boots

  function initialize() {}

  exports['default'] = {
    name: 'ember-css-modules',
    initialize: initialize
  };
});