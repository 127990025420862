define('ember-singularity-mixins/mixins/scroll-handler', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _Ember$Mixin$create;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
      obj[key] = value;
    }return obj;
  }

  /**
   * The scroll-handler mixin adds an easy-to-use "scroll" hook, similar to the
   * default Ember hook for click(). It is only applicable to views/components.
   */

  var SCROLL = 'scroll';
  var EVENTTARGET = 'eventTarget';
  var WINDOW = 'window';

  exports['default'] = _ember['default'].Mixin.create((_Ember$Mixin$create = {
    unifiedEventHandler: _ember['default'].inject.service()

  }, _defineProperty(_Ember$Mixin$create, EVENTTARGET, WINDOW), _defineProperty(_Ember$Mixin$create, SCROLL, undefined), _defineProperty(_Ember$Mixin$create, 'triggerOnInsert', false), _defineProperty(_Ember$Mixin$create, 'registerScrollHandlers', _ember['default'].on('didInsertElement', function () {
    // TODO: limit this to the views object (this.$()) or the window
    var eventTarget = this.get(EVENTTARGET);

    // Bind 'this' context to the scroll handler for when passed as a callback
    var scroll = this.get(SCROLL).bind(this);

    // Save the newly bound function back as a reference for deregistration.
    this.set(SCROLL, scroll);

    this.get('unifiedEventHandler').register(eventTarget, SCROLL, scroll);

    this._scrollHandlerRegistered = true;

    if (this.get('triggerOnInsert')) {
      _ember['default'].run.scheduleOnce('afterRender', scroll);
    }
  })), _defineProperty(_Ember$Mixin$create, 'unregisterScrollHandlers', _ember['default'].on('willDestroyElement', function () {
    if (this._scrollHandlerRegistered) {
      var _scroll = this.get(SCROLL);
      var eventTarget = this.get(EVENTTARGET);
      this.get('unifiedEventHandler').unregister(eventTarget, SCROLL, _scroll);
      this._scrollHandlerRegistered = false;
    }
  })), _Ember$Mixin$create));

  // The target of the scrolling event, defaults to the window

  // The hook for your scroll functionality, you must implement this

  // Whether to trigger the scroll handler on initial insert

  // Setups up the handler binding for the scroll function

  // Unbinds the event handler on destruction of the view
});